/* body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* ------------------------- Separate line ------------------------- */
:root {
  --breadcrumb-theme-1: #512581;
  --breadcrumb-theme-2: #fff;
  --breadcrumb-theme-3: #3e1d61;
  --breadcrumb-theme-4: #3e1d61;
}
.breadcrumb {
  text-align: center;
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
  counter-reset: flag;
  background-color:  inherit;
  margin: 0;
  padding: 0;
  display: flex;
}
.breadcrumb__step {
  text-decoration: none;
  outline: none;
  display: block;
  float: left;
  font-size: 12px;
  line-height: 36px;
  padding: 0 10px 0 60px;
  position: relative;
  background: var(--breadcrumb-theme-2);
  color: var(--breadcrumb-theme-1);
  transition: background 0.5s;
  flex: 1;
}
.breadcrumb__step:first-child {
  padding-left: 46px;
  border-radius: 5px 0 0 5px;
}
.breadcrumb__step:first-child::before {
  left: 14px;
}
.breadcrumb__step:last-child {
  border-radius: 0 5px 5px 0;
  padding-right: 20px;
}
.breadcrumb__step:last-child::after {
  content: none;
}
.breadcrumb__step::before {
  content: counter(flag);
  counter-increment: flag;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  position: absolute;
  top: 0;
  left: 30px;
  font-weight: bold;
  background: var(--breadcrumb-theme-2);
  box-shadow: 0 0 0 1px var(--breadcrumb-theme-1);
}
.breadcrumb__step::after {
  content: '';
  position: absolute;
  top: 0;
  right: -18px;
  width: 41px;
  height: 41px;
  transform: scale(0.707) rotate(45deg);
  z-index: 1;
  border-radius: 0 5px 0 50px;
  background: var(--breadcrumb-theme-2);
  transition: background 0.5s;
  box-shadow: 2px -2px 0 2px var(--breadcrumb-theme-4);
}
.breadcrumb__step--active {
  color: var(--breadcrumb-theme-2);
  background: var(--breadcrumb-theme-1);
}
.breadcrumb__step--active::before {
  color: var(--breadcrumb-theme-1);
}
.breadcrumb__step--active::after {
  background: var(--breadcrumb-theme-1);
}
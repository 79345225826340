body {
  color: var(--font-color);
}
.flex {
  display: flex;
}

#page {
  background-color: var(--body-bg);
  min-height: 100vh;
  overflow-x: inherit;
}

.search-input-wrapper input {
  box-shadow: 0 2px 8px 2px rgba(0,0,0,0.1);
  background-color: #fff;
  width: 80%;
  display: inline-block;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
  border: 0;
}

.search-input-wrapper button {
  box-shadow: 0 2px 8px 2px rgba(0,0,0,0.1);
  background-color: #FF4C28;
  color: #fff;
  border: 0;
  height: 54px;
  border-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: bold;
  width: 54px;
}

.card-sector-title {
  position: absolute;
  bottom: 25px;
  left: 25px;
  right: 25px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  word-break: break-word;
}

.btn-primary {
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.btn-primary:hover {
  background: var(--secondary-color)!important;
  border: 2px solid var(--secondary-color)!important;
}

.btn-primary:focus {
  background: var(--secondary-color)!important;
  border: 2px solid var(--secondary-color)!important;
}

.card-sector {
  overflow: hidden;
  border-radius: 15px;
}

.card-sector .blog-img-holder{
  border-radius: 15px;
}

.stduies-card {
  background-color: #fff;
  color: #282828;
}

.stduies-card img {
  max-width: 100%;
}

.page-cover {
  height: 250px;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
}

.page-cover::after {
  content: '';
  height: 250px;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 0;
}

.page-cover > div {
  z-index: 1;
}

.page-cover-title {
  color: #fff;
  align-self: flex-end;
  margin-bottom: 30px;
}

.page-cover-employment {
  color: #fff;
  align-self: flex-end;
  margin-bottom: 30px;
  font-weight: 600;
  margin-top: 15px;
}

.sheet-section {
  background-color:red;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between
}

.page-cover-1 {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-cover-2 {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.page-cover-2::before {
  /* content: ''; */
  height: 150px;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.page-cover-2 .form-control {
  background-color: #fff;
}

.page-cover-2 h1 {
  color: #fff;
  font-weight: bold;
  text-align: center;
  z-index: 1;
}

.page-cover-2 h2 {
  color: #fff;
  text-align: center;
  font-size: 1.32em;
  font-weight: bold;
}

.search-container {
  background-color: #edeef2;
  border-radius: 10px;
  padding: 10px;
}

.search-container .form-control {
  background-color: #fff;
}

.search-filter {
  background-color: #fff;
  padding: 14px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
}

.search-filter-title {
  color: var(--heading-color);
  font-weight: bold;
  border-left: 5px solid var(--secondary-bg);
  padding-left: 8px;
}

.search-results-title{
  background-color: #fff;
  padding: 14px;
}

.search-results-title h2{
  font-weight: bold;
  font-size: 24px;
}

.search-button {
  background-color: var(--primary-bg);
  color: #fff;
  border: 0;
  height: 54px;
  font-weight: bold;
  width: 100%;
}

.school-card {
  padding: 14px;
  background-color: #fff;
  margin-top: 14px;
  border-radius: 5px;
  color: #000;
  border: 1px solid #eeeeee;
}

.school-card-title {
  font-weight: bold;
  font-size: 20px;
}

.school-card-logo {
  text-align: right;
}

.page-cover-3 {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.page-cover-3 .logo-card {
  background-color: #fff;
  width: 350px;
  position: absolute;
  top: 30px;
  padding: 14px;
  border-radius: 3px;
  text-align: center;
  border: 1px solid #eeeeee;
}

.page-cover-3 h1 {
  color: #fff;
  font-weight: bold;
  text-align: left;
}

.fh5co-blog .blog-img-holder:hover {
  transform: scale(1.2);
}

.fh5co-blog .blog-img-holder::after {
  content: '';
  width: 100%;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  border-radius: 5px;
}

.page-title {
  font-size: 25px;
  font-weight: bold;
  border-left: solid 5px var(--border-color);
  padding-left: 8px;
  color: var(--primary-color);
}

.search-item-title {
  color: #233266;
  font-weight: bold;
  font-size: 20px;
}


.text-error {
  color: #dc3545;
  font-weight: normal;
  font-size: 12px;
}

.contact-success {
  padding: 10px;
  background-color: green;
  opacity: 0.5;
  color: #fff;
  margin-bottom: 10px;
}


.fh5co-blog .blog-text {
  word-break: break-word;
}

.current-filter {
  background-color: #FF4C28;
  color: #fff;
  padding: 5px 10px;
}


.search-filter-item {
  margin-bottom: 10px;
}

.card {
  box-shadow: 0 8px 10px 5px rgba(0,0,0,0.1);
  border: 2px solid #233266;
  padding: 10px;
  border-radius: 5px;
  margin: 30px;
}

.card-simple {
  border: 1px solid #eeeeee;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

.desc-employement {
  height: 660px;
}

.offre-employement {
  height: 500px;
}

a.list-group-item:before {
  content: '';
}

.list-group-item {
  margin-left: 0px;
  padding-left: 15px;
}

.list-group-item .row > div.col-sm-5 {
  margin-left: 10px;
}

.list-group-item .row > div.col-sm-6 {
  margin-left: -10px;
}

.panel-title {
  margin-top: 13px;
  margin-right: 7px;
}

.list-group-item-heading,
.list-group-item-text {
  padding-left: 3px;
}

.form-inline {
  padding-right: 1.1em;
}

.form-inline .btn-primary {
  margin-top: 0.5em;
}

@media only screen and (min-width: 768px) {
  .form-inline {
    padding-right: 0;
  }

  .form-inline .btn-primary {
    margin-top: 0;
    margin-left: 2em;
  }
}

@media only screen and (max-width: 767px) {
  .list-group-item .row > div.col-sm-5 {
    margin-left: 0px;
    padding-left: 5px;
  }
  .list-group-item-heading {
    margin-top: 3px;
  }
}

@media (max-width: 250px) {
  #search {
    padding-left: 10px;
  }
}

.button-top-menu-left {
  font-size: 13px;
  border: 1px solid #233266;
  font-weight: bold;
}

.button-top-menu-right {
  border: 1px solid #233266;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  font-weight: bold;
  min-width: 143px;
}

.section {
  padding-top: 42px;
  padding-bottom: 42px;
}

.section-blurb {
  display: table;
  max-width: 832px;
}

.section-blurb .section-blurb-img-container {
  vertical-align: middle;
  display: table-cell;
}

.section-blurb .section-blurb-text-container {
  vertical-align: middle;
  display: table-cell;
  padding: 20px;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea,
#contact button[type="submit"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

#contact {
  background: #F9F9F9;
  padding: 15px;
  margin: 15px 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

#contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #4CAF50;
  color: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

#contact button[type="submit"]:hover {
  background: #43A047;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}

#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}

.btn-tertiary {
  color: #555;
  padding: 0;
  line-height: 40px;
  width: 300px;
  margin: auto;
  display: block;
  border: 2px solid #555;
}
.btn-tertiary:hover, .btn-tertiary:focus {
  color: #888888;
  border-color: #888888;
}

/* input file style */
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.input-file + .js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
}
.input-file + .js-labelFile .icon:before {
  content: "\f093";
}
.input-file + .js-labelFile.has-file .icon:before {
  content: "\f00c";
  color: #5AAC7B;
}
#school-contact-form .form-control {
  margin-bottom: 15px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}


.bloc-become-teacher {
  /* height: 300px; */
  background-color: var(--primary-color);
  /* display: flex; */
  align-items: center;
  color: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 12px 60px rgb(0 0 0 / 20%);
}

.bloc-become-teacher p {
  color: #fff;
}

.bloc-become-teacher.swip {
  background-color: var(--secondary-color);
}

.bloc-become-teacher .btn {
  background-color:#BE1823;
  font-weight: bold;
  padding: 15px 15px
}

.bloc-become-teacher.swip .btn{
  background-color:#512581;
}


.bloc-become-teacher .title {
  color: #fff;
  font-size: 36px;
  font-weight: bold;
}

.bloc-become-teacher .subtitle {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
}

.bloc-become-teacher .text {
  font-size: 16px;
}

.card-category {
  padding: 40px 30px;
  transition: 0.3s ease-out;
  border-radius: 4px;
  background-color: #fff;
  /* box-shadow: 0 0 30px #cccccc73; */
  border: 1px solid #cccccc73;
  /* text-align: center; */
}

.card-category .fa {
  font-size: 26px;
  color: #233266;
}

.card-category .title {
  color: var(--header-color);
  font-weight: bold;
  font-size: 20px;
  padding-top: 25px;
}


.request-block {
  padding: 30px 15px 30px;
  background: #fafafa;
}

.clap {
  width: 100%;
  height: 300px;
  background: #fff;
  display: flex;
  /* align-items: center; */
  padding: 40px;
  flex-direction: column;
  justify-content: center;
}

.noPdleft {
  padding-left: 0;
}

.noPdright {
  padding-right: 0;
}

.btn-rounded {
  border-radius: 50px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.modal-footer .btn {
  min-width: 110px;
}

.btn {
  border-width: 2px;
}

.Toastify__toast--default {
  color: red!important;
}

.btn .fas, .btn .far {
  margin-right: 5px;
}


#fh5co-hero .flexslider .slides.tutoring li {
  height: 600px;
}

#fh5co-hero.tutoring {
  min-height: 600px;
  height: 600px;
}

#fh5co-hero .flexslider .slider-text > .slider-text-inner h1 {
  font-size: 50px;
  font-weight: bold;
  text-align: left;
}

#fh5co-hero.tutoring .flexslider .slider-text > .slider-text-inner p::before {
     position: absolute;
    content: "";
    background: #fff;
    width: 60px;
    height: 3px;
    bottom: 100px;
}

#about.w3ls-bnrbtm {
  /* background-image: url("/images/tutoring-wave.svg"); */
  /* background-size: cover; */
}

#about.w3ls-bnrbtm .btn{
  /* background-color: #B35389; */
}

#about.w3ls-bnrbtm p{
  /* color: #fff; */
}

#about.w3ls-bnrbtm h5{
  /* color: #fff; */
}


.action-card {
  /* background-image: url("/images/teacher-signup-bg.jpg"); */
  background-size: cover;
  display: flex;
  padding: 20px;
  align-items: center;
  border-radius: 20px;
  color: #fff;
  font-weight: bold;
  box-shadow:0 8px 10px 5px rgb(0 0 0 / 10%);
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  color: #000;
}

.action-card .action::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  /* background: rgba(0, 0, 0, 0.2); */
  z-index: 1;
  border-radius:20px;
  z-index: -1;
}

.nav-top a{
  margin-left: 10px;
}

.searching {
  position: relative;
  margin-top: -5em;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.4);
}

.searching .search-container {
  background-color: transparent;
}

.searching .search-container .form-control {
  background-color: #fff;
}

#fh5co-hero.test .flexslider .slides li {
  height: 700px;
}
#fh5co-hero.test {
  height: 700px;
}


.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 54px;
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 15px;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 55px;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 0.375rem 0.75rem;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

/* - */
.row.bg-colors .service-subgrids {
  background-color: #fff;
}

.row.bg-colors .service-subgrids .text-wh {
  color: #000;
}
.row.bg-colors .service-subgrids:nth-of-type(odd) {
  background-color: #512581;
}
.row.bg-colors .service-subgrids:nth-of-type(odd) .text-wh {
  color: #fff;
}

.row.bg-colors .service-subgrids:nth-of-type(odd) .text {
  color: #fff;
}

img {
  max-width: 100%;
}

.sticky-top {
  position: sticky;
  top: 20px;
  z-index: 1;
}

.offer-cover {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 9px;
  min-height: 200px;
}

h3.hny-title {
  text-transform: initial;
  font-weight: bold;
  color: #DD2421;
}


.owl-nav {
  position: absolute;
  top: -15px;
  right: 11px;
  color: #fff;
  font-size: 34px;
}

.owl-prev {
  margin-right: 10px;
}

.owl-item {
  height: 100%;
}

#fh5co-about {
  padding: 2em 0;
}

.top-menu {
  padding: 5px;
}

.top-menu p {
  margin: 0;
}


.hny-title.clrs-1 {
  color: #0BA094;
}

.hny-title.clrs-2 {
  color: #BE1823;
}

.hny-title.clrs-3 {
  color: #512581;
}

.top-search-input {
  height: 40px;
  width: 280px;
  background-color: #fff;
  margin-right: 10px;
}

.css-2b097c-container.is-invalid .css-yk16xz-control {
  border-color: #dc3545;
}

.css-yk16xz-control {
  min-height: 54px!important;
  border-radius: 30px!important;
}

 .css-1pahdxg-control {
  min-height: 54px!important;
  border-radius: 30px!important;
 }

.css-1pahdxg-control:hover {
  border-color: #512581!important;
}

.color-primary {
  color: #512581!important;
}


.rouded-bg {
  background-color: var(--secondary-color);
  padding: 35px 20px;
  color: #fff;
  /* display: inline-block; */
  border-radius: 30px;
  font-size: 1.8em;
  text-transform: uppercase;
}

.slider-text p {
  font-size: 1.5rem;
}


.search-box.mobile {
  margin-top: 6px;
  display: none;
}

@media screen and (max-width: 552px) {

  .search-box.full {
    display: none;
  }

  .search-box.mobile {
    display: block;
  }
}



.cligner  {
  animation-duration: 3s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
}
@keyframes clignoter {
 0%   { opacity:1; }
 40%   {opacity:0.5; }
 40%   {opacity:0.4; }
 100% { opacity:1; }
}
